<template>
  <a-drawer
    :title="id && !isCopy ? '修改推广链接' : '创建推广链接'"
    width="800"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 名称 -->
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.name"
          placeholder="请输入"
          :maxlength="30"
        />
      </a-form-item>
      <!-- 客户端 -->
      <a-form-item
        label="客户端"
        name="project_platform_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.project_platform_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :disabled="!!id && !isCopy"
          @change="changePlatform"
        >
          <a-select-option
            v-for="item in platforms"
            :key="`${item.project_platform_id}-${item.name}`"
            :value="item.project_platform_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 着陆页 -->
      <a-form-item
        label="着陆页"
        name="page_where"
        :rules="[{ required: true }]"
      >
        <a-radio-group
          class="form-input"
          v-model:value="formState.page_where"
          :disabled="!!id && !isCopy"
        >
          <a-radio
            v-for="item in LandingTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 短剧 -->
      <a-form-item
        v-if="formState.page_where === 1"
        label="短剧"
        name="drama_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          show-search
          v-model:value="formState.drama_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filter-option="filterOption"
          :disabled="!!id && !isCopy"
          @change="onChangeDrama"
        >
          <a-select-option
            v-for="item in dramas"
            :key="`${item.drama_id}-${item.name}`"
            :value="item.drama_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 播放起始 -->
      <a-form-item
        v-if="formState.page_where === 1"
        label="播放起始"
        name="eq_number"
        :rules="[{ required: true, validator: validatorPlayStartNo  }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.eq_number"
          placeholder="请输入"
          suffix="集起"
          :maxlength="30"
          :disabled="!formState.drama_id"
        />
      </a-form-item>
      <!-- 付费起始 -->
      <a-form-item
        v-if="formState.page_where === 1"
        label="付费起始"
        name="start_pay_eq"
        :rules="[{ required: true, validator: validatorStartPayEq  }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.start_pay_eq"
          placeholder="请输入"
          suffix="集起"
          :maxlength="30"
          :disabled="!formState.drama_id"
        />
        <template v-if="drama.start_pay_eq">
          <span style="padding: 0 10px; color: #5A5A5A;">系统设定 {{ drama.start_pay_eq }}</span>
          <a @click="touchStartPayEq">填入</a>
        </template>
      </a-form-item>
      <!-- 每次广告解锁 -->
      <a-form-item
        label="每次广告解锁"
        name="unlock_eq_num"
        :rules="[{ required: true, validator: validatorUnlockEqNum  }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.unlock_eq_num"
          placeholder="请输入"
          suffix="集"
          :maxlength="30"
        />
      </a-form-item>
      <!-- 推广渠道 -->
      <a-form-item
        label="推广渠道"
        name="channel"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.channel"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :disabled="!!id && !isCopy"
          @change="changeChannel"
        >
          <template v-for="item in ChannelTypes" :key="item.value">
            <a-select-option
              v-if="item.platform.includes(formState.platform_id)"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </template>
        </a-select>
      </a-form-item>
      <!-- 广点通商品ID -->
      <a-form-item
        v-if="formState.channel === 3"
        label="广点通商品ID"
        :name="['remark', 'product_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.product_id"
          placeholder="请输入"
        >
        </a-input>
      </a-form-item>
      <!-- 调起方式 -->
      <a-form-item
        label="调起方式"
        name="channel_type"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.channel_type"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in CallbackModes"
            :key="item.value"
            :value="item.value"
            :disabled="!(item.channel.includes(formState.channel) && item.platform.includes(formState.platform_id))"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 关键事件回传方式 -->
      <a-form-item
        v-if="formState.channel != 4"
        label="关键事件回传"
        name="callback_type"
        :rules="[{ required: true }]"
        
      >
        <a-radio-group class="form-input" v-model:value="formState.callback_type" style="display: flex; width: 100%; margin-top: 5px;">
          <a-radio
            v-for="item in IAACallbackTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
        <div style="margin-top: 12px;"><a @click="touchRecord" v-if="!!id && !isCopy">操作记录</a></div>
      </a-form-item>
      <!-- 关键事件定义 -->
      <a-form-item
        v-if="formState.channel != 4 && formState.callback_type !== 1"
        label="关键事件定义"
        style="margin-bottom: 0px;"
        :rules="[{ required: true }]"
      >
        <div style="display: flex; width: 100%;">
          <a-form-item
            style="width: 50%;"
          >
            <a-input
              class="form-input"
              style="flex-shrink: 0; width: 100%;"
              placeholder="观看广告次数(IPU)"
              disabled
            >
            </a-input>
          </a-form-item>
          <a-form-item
            style="width: 50%;"
            name="visible_num"
            :rules="[{ required: true, validator: validatorVisibleNum }]"
          >
            <div 
              class="recharge-num form-input"
              style="margin-left: 8px; width: 100%;"
            >
              <span>≥</span>
              <a-input
                style="width: 100%;"
                placeholder="请输入"
                v-model:value="formState.visible_num"
              >
              </a-input>
            </div>
          </a-form-item>
        </div>

        <div style="display: flex; width: 100%;" v-if="formState.channel != 3">
          <a-form-item
            style="width: 50%;"
          >
            <a-input
              class="form-input"
              style="flex-shrink: 0; width: 100%;"
              placeholder="累计广告收入(ARPU)"
              disabled
            >
            </a-input>
          </a-form-item> 
          <a-form-item
            style="width: 50%;"
            name="ad_income"
            :rules="[{ required: true, validator: validatorVisibleFloat }]"
          >
            <div 
              class="recharge-num form-input"
              style="margin-left: 8px; width: 100%;"
            >
              <span>≥</span>
              <a-input
                style="width: 100%;"
                placeholder="请输入"
                v-model:value="formState.ad_income"
              >
              </a-input>
            </div>
          </a-form-item>
        </div>
        <div style="display: flex; width: 100%;" v-if="formState.channel != 3">
          <a-form-item
            style="width: 50%;"
          >
            <a-input
              class="form-input"
              style="flex-shrink: 0; width: 100%;"
              placeholder="千次广告平均收入(ECPM)"
              disabled
            >
            </a-input>
          </a-form-item>
          <a-form-item
            style="width: 50%;"
            name="average_ad_income"
            :rules="[{ required: true, validator: validatorVisibleFloat }]"
          >
            <div 
              class="recharge-num form-input"
              style="margin-left: 8px; width: 100%;"
            >
              <span>≥</span>
              <a-input
                style="width: 100%;"
                placeholder="请输入"
                v-model:value="formState.average_ad_income"
              >
              </a-input>
            </div>
          </a-form-item>
        </div>
        <div style="display: flex; width: 100%;" v-if="formState.channel != 3">
          <a-form-item
            style="width: 50%;"
          >
            <a-input
              class="form-input"
              style="flex-shrink: 0; width: 100%;"
              placeholder="单笔广告收入（SAR）"
              disabled
            >
            </a-input>
          </a-form-item>
          <a-form-item
            style="width: 50%;"
            name="sar_num"
            :rules="[{ required: true, validator: validatorVisibleFloat }]"
          >
            <div 
              class="recharge-num form-input"
              style="margin-left: 8px; width: 100%;"
            >
              <span>≥</span>
              <a-input
                style="width: 100%;"
                placeholder="请输入"
                v-model:value="formState.sar_num"
              >
              </a-input>
            </div>
          </a-form-item>
        </div>
      </a-form-item>
      <!-- 回传比例 -->
      <a-form-item
        v-if="formState.channel != 4 && formState.callback_type !== 1"
        label="回传比例"
        :rules="[{ required: true }]"
      >
        <div style="display: flex; width: 100%;">
          <div style="line-height: 32px; margin-right: 10px;">回传</div>
          <a-form-item
            style="width: 20%;"
            name="callback_num"
            :rules="[{ required: true, validator: validatorVisibleNumPeople }]"
          >
            <a-input
              class="form-input"
              style="flex-shrink: 0; width: 100%;"
              suffix="人"
              placeholder="请输入"
              v-model="formState.callback_num"
            >
            </a-input>
          </a-form-item>
          <div style="line-height: 32px; margin: 0 10px;">后回传</div>
          <a-form-item
            style="width: 40%;"
            name="callback_percent"
            :rules="[{ required: true, validator: validatorVisibleScale }]"
          >
              <a-input
                style="width: 100%;"
                placeholder="请输入回传比例"
                suffix="%"
                v-model:value="formState.callback_percent"
              >
              </a-input>
          </a-form-item>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <!-- <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template> -->
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 操作记录 -->
    <OperationRecordDrawer ref="RefOperationRecordDrawer"></OperationRecordDrawer>
  </a-drawer>
</template>

<script setup>
import { nextTick, reactive, ref } from 'vue'
import store from '@/store'
import Pub from '@/utils/public'
import Loading from '@/components/Loading'
import OperationRecordDrawer from './OperationRecordDrawer'
import { message } from 'ant-design-vue'
import { IAACallbackModes as CallbackModes, IAAChannelTypes as ChannelTypes, LandingTypes, IAACallbackTypes } from '@/utils/constantList'
import { iaaPopularizeCreate, platformList, dramaList, chargeAll } from '@/api/reseller'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 操作记录
const RefOperationRecordDrawer = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 客户端
let platforms = ref([])
// 短剧列表
let dramas = ref([])
// 当前选中的短剧
let drama = ref({})
// id
let id = ref(undefined)
// 详情数据
let detail = ref({})
// 复制创建
let isCopy = ref(false)
// 表单参数
const formState = reactive({
  // 名称
  name: undefined,
  // 落地页类型
  page_where: 1,
  // 短剧
  drama_id: undefined,
  // 播放起始
  eq_number: 1,
  // 付费起始
  start_pay_eq: undefined,
  // 每次广告解锁
  unlock_eq_num: undefined,
  // 客户端
  project_id: undefined,
  platform_id: undefined,
  project_drama_id: undefined,
  project_platform_id: undefined,
  // 推广渠道
  channel: undefined,
  // 调起方式
  channel_type: undefined,
   // 关键事件：1-不回传 2-满足所有条件回传 3-满足任意条件
  visible_iaa: undefined,
  // 关键事件：1-不回传 2-满足所有条件回传 3-满足任意条件
  callback_type: undefined,
  // 观看广告次数
  visible_num: undefined,
  // 累计广告收入
  ad_income: undefined,
  // 千次平均广告收入
  average_ad_income: undefined,
  // 单笔广告收入
  sar_num: undefined,
  // 回传人数
  callback_num: undefined,
  // 回传比例
  callback_percent: undefined,
  // 配置
  remark: {
    // 广点通商品ID
    product_id: undefined,
  }
})

// 显示
function showDrawer (record, copy) {
  // 详情
  if (record.id) {
    // 复制创建
    isCopy.value = copy
    // 记录详情
    id.value = record.id
    detail.value = record
    // 取出参数值
    for (let i in formState) {
      const v = record[i]
      if (i === 'recharge_detail') {
        // formState[i] = [].concat(v || [])
      } else if (i === 'remark') {
        formState[i].product_id = v ? v.product_id : undefined
      } else {
        formState[i] = v
      }
    }
    // 获取短剧列表
    getDramaAll()
  } else {
    // 优化显示
    formState.drama_id = undefined
  }
  // 显示
  visible.value = true
  // 获取选择项
  getSelectList()
}

// 获取选择项
function getSelectList () {
  platformList({ type: 1 }).then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      platforms.value = data
    }
  })
}
 
// 切换渠道
function changeChannel () {
  // 清空回传方式
  formState.channel_type = undefined
}

// 切换短剧
function onChangeDrama(e) {
  drama.value = dramas.value.find(item => item.drama_id === e) || {}
  formState.project_drama_id = drama.value.project_drama_id
  if (formState.start_pay_eq) {
    RefForm.value.validateFields(['start_pay_eq'])
  }
}

// 短剧列表
function getDramaAll () {
  dramaList({ project_id: formState.project_id, platform_id: formState.platform_id }).then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
      // 同步剧集信息
      onChangeDrama(formState.drama_id)
    }
  })
}

// 填入付费起始
function touchStartPayEq () {
  formState.start_pay_eq = drama.value.start_pay_eq
  RefForm.value.validateFields(['start_pay_eq'])
}

// 操作记录
function touchRecord () {
  RefOperationRecordDrawer.value.showDrawer(id.value)
}

// 播放起始
function validatorPlayStartNo (rule, value) {
  if (!value) {
    return Promise.reject('请输入播放起始')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (value > drama.value.total_ep) {
    return Promise.reject('不得大于短剧总集数 ' + drama.value.total_ep)
  }
  return Promise.resolve()
}

// 付费起始
function validatorStartPayEq(rule, value) {
  // 否则校验自身
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  // else if (value > drama.value.total_ep) {
  //   return Promise.reject('不得大于短剧总集数 ' + drama.value.total_ep)
  // }
  return Promise.resolve()
}

// 每次广告解锁
function validatorUnlockEqNum(rule, value) {
  // 否则校验自身
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (value > drama.value.total_ep) {
    return Promise.reject('不得大于短剧总集数 ' + drama.value.total_ep)
  }
  return Promise.resolve()
}

// 回传人数
function validatorVisibleNumPeople (rule, value) {
  if (!value && value !== 0) {
    return Promise.reject('　请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}

// 事件次数
function validatorVisibleNum (rule, value) {
  if (!value && value !== 0) {
    return Promise.reject('　请输入')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('最多两位小数')
  } else if (formState.callback_type === 3 && value == 0) {
    return Promise.reject('不能为 0')
  }
  return Promise.resolve()
}

// 比例
function validatorVisibleScale (rule, value) {
  if (!value && value !== 0) {
    return Promise.reject('　请输入')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('最多两位小数')
  } else if (value > 100) {
    return Promise.reject('回传比例不能超过 100%')
  }
  return Promise.resolve()
}

function validatorVisibleFloat (rule, value) {
  if (!value && value !== 0) {
    return Promise.reject('　请输入')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  } else if (formState.callback_type === 3 && value == 0) {
    return Promise.reject('不能为 0')
  }
  return Promise.resolve()
}

//  提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 累计广告收入
    // formState.ad_income = formState.ad_income * 100
    // 千次广告平均收入
    // formState.average_ad_income = formState.average_ad_income * 100
    // 单笔广告收入
    // formState.sar_num = formState.sar_num * 100
    // 上传数据
    const params = {
      // 复制创建，也算正常创建
      id: isCopy.value ? undefined : id.value,
      ...formState,
      ad_income: formState.ad_income * 1000 / 10,
      average_ad_income: formState.average_ad_income * 1000 / 10,
      sar_num: formState.sar_num * 1000 / 10
    }
    // 新增
    isLoading.value = true
    iaaPopularizeCreate(params).then((res) => {
      isLoading.value = false
      if (res.code === 0) {
        if (params.id) {

          message.success('编辑成功')
        } else {
          message.success('提交成功')
        }
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    }).catch(() => {
      isLoading.value = false
      if (params.id) {
        message.error('编辑失败')
      } else {
        message.error('提交失败')
      }
    })
  })).catch((err) => {
    // console.log(err)
    if (params.id) {
      message.error('编辑失败')
    } else {
      message.error('提交失败')
    }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    formState.name = undefined
    formState.page_where = 1
    formState.drama_id = undefined
    formState.eq_number = 1
    formState.start_pay_eq = undefined
    formState.unlock_eq_num = undefined
    formState.project_id = undefined
    formState.platform_id = undefined
    formState.project_drama_id = undefined
    formState.project_platform_id = undefined
    formState.channel = undefined
    formState.channel_type = undefined
    formState.visible_iaa = undefined
    formState.visible_num = undefined
    formState.callback_type = undefined
    formState.ad_income = undefined
    formState.average_ad_income = undefined
    formState.sar_num = undefined
    formState.callback_num = undefined
    formState.callback_percent = undefined
    formState.remark.product_id = undefined
    id.value = undefined
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 切换选中客户端
function changePlatform (e) {
  const p = platforms.value.find(item => item.project_platform_id === e)
  if (p) {
    formState.project_id = p.project_id
    formState.platform_id = p.platform_id
  }
  formState.drama_id = undefined
  formState.channel = undefined
  formState.channel_type = undefined
  getDramaAll()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-input {
  width: 50%;
}
.coin-list {
  background-color: #f8f8f8;
  width: 630px;
  line-height: 36px;
  padding: 16px;
  margin: 0 0 24px 124px;
}
.gap {
  margin: 0 8px;
}
.tag-type {
  margin-left: 12px;
  background: #eee;
}
.recharge-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.recharge-num {
  display: flex;
  align-items: center;
  padding-left: 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  white-space: nowrap;
  margin-right: 12px;
  flex-shrink: 1;
  /deep/ .ant-select-selector {
    border: none !important;
    border-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
  }
  /deep/ .ant-input {
    border: none !important;
    border-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
  }
}
.recharge-delete {
  margin-left: 12px;
  white-space: nowrap;
}
.callback-hint {
  color: #AAA;
  margin-top: -20px;
  margin-bottom: 10px;
}
</style>