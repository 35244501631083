<template>
  <a-drawer
    title="关键事件操作记录"
    width="700"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <!-- <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'new_pay_user_rate'">
          {{ getNewPayUserRate(record) }}
        </template>
      </template> -->
    </a-table>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { iaaPopularizeLog } from '@/api/reseller'
import Loading from '@/components/Loading'

// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前ID
let advID = ref(null)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '操作时间',
    dataIndex: 'operate_time',
    key: 'operate_time'
  },
  {
    title: '设置内容',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '操作人',
    dataIndex: 'create_username',
    key: 'create_username'
  }
])

// 显示
function showDrawer (id) {
  advID.value = id
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 获取数据详情列表
function getList () {
  isLoading.value = true
  const params = {
    adv_iaa_id: advID.value
  }
  iaaPopularizeLog(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>