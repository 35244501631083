<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>分销管理</a-breadcrumb-item>
    <a-breadcrumb-item>IAA推广链接汇总</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 消息提示 -->
  <div class="alert-view">
    <a-alert
      type="warning"
      show-icon
    >
      <template #message>
        今日数据更新于 {{ lastTime ? dayjs(lastTime).format('HH:mm:ss') : '00:00:00' }}
        <a style="margin-left: 10px;" @click="refreshData">点击更新数据</a>
        <loading-outlined v-if="isRefreshData" style="margin-left: 6px;" />
      </template>
    </a-alert>
  </div>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <!-- 推广 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">推广：</a-col>
          <a-col :span="18">
            <a-input v-model:value="search" style="width: 100%;" placeholder="请输入ID或名称" />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 分销商 -->
          <a-col :span="6" class="tool-title">分销商：</a-col>
          <a-col :span="18">
            <a-select 
              v-model:value="resellerID"
              style="width: 100%;"
              placeholder="请选择"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option
                v-for="item in resellers"
                :key="`${item.id}-${item.name}`"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <!-- <a-select 
              v-model:value="pitcher"
              style="width: 100%;"
              placeholder="请选择"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option
                v-for="item in pitchers"
                :key="`${item.id}-${item.nickname}`"
                :value="item.id"
              >
                {{ item.nickname }}
              </a-select-option>
            </a-select> -->
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 推广渠道 -->
          <a-col :span="6" class="tool-title">推广渠道：</a-col>
          <a-col :span="18">
            <a-select v-model:value="channelType" style="width: 100%;" placeholder="请选择">
              <a-select-option :value="undefined">全部</a-select-option>
              <a-select-option
                v-for="item in ChannelTypes"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 客户端 -->
          <a-col :span="6" class="tool-title">客户端：</a-col>
          <a-col :span="18">
            <a-select 
              v-model:value="platform"
              style="width: 100%;"
              placeholder="请选择"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option
                v-for="item in platforms"
                :key="`${item.project_platform_id}-${item.name}`"
                :value="item.project_platform_id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 短剧 -->
          <a-col :span="6" class="tool-title">短剧：</a-col>
          <a-col :span="18">
            <a-select
              style="width: 100%;"
              show-search
              v-model:value="dramaID"
              placeholder="请选择"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="item in dramas"
                :key="`${item.id}-${item.name}`"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 推广日期 -->
          <a-col :span="6" class="tool-title">染色日期：</a-col>
          <a-col :span="18">
            <a-range-picker
              v-model:value="dateRange"
              :format="dateFormat"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 推广 -->
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <!-- 操作 -->
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
            <!-- 弹簧 -->
            <div style="flex: 1;"></div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 推广 -->
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap; text-align: right;">
            <!-- 导出 -->
            <a-button @click="touchDownload()">导出数据</a-button>
            <!-- 新建 -->
            <!-- <a-button type="primary" @click="touchEdit()">
              <plus-outlined /> 新建
            </a-button> -->
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <div class="list-view">
    <!-- 列表 -->
    <a-table
      class="table-view"
      :customRow="customRow"
      :data-source="dataSource"
      :scroll="{ x: scrollX, y: 'max-content' }"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <template #headerCell="{ column }">
        <template v-if="column.key === 'callback_rule_name'">
          <!-- 设置列 -->
          <div style="display: flex; align-items: center; ">
            <!-- 列名 -->
            <span>{{ column.title }}</span>
            <div style="flex: 1"></div>
            <!-- 眼睛 -->
            <EyeOutlined style="cursor: pointer;" @click="() => isVisibleCallbackRule = false" v-if="isVisibleCallbackRule" />
            <EyeInvisibleOutlined style="cursor: pointer;" @click="() => isVisibleCallbackRule = true" v-else />
          </div>
        </template>
        <template v-else-if="column.key === 'operation'">
          <!-- 设置列 -->
          <div style="display: flex; align-items: center;">
            <!-- 列名 -->
            <span>{{ column.title }}</span>
            <div style="flex: 1"></div>
            <!-- 下拉菜单 -->
            <a-dropdown v-model:visible="visible">
              <setting-outlined style="cursor: pointer;" @click.prevent/>
              <template #overlay>
                <a-menu>
                  <a-menu-item>自定义列</a-menu-item>
                  <a-menu-item v-for="item in cusColumns" :key="item.key"><a-checkbox v-model:checked="item.status">{{ item.title }}</a-checkbox></a-menu-item>
                  <a-menu-item>
                    <a-button
                      type="primary"
                      style="width: 100%;"
                      size="small"
                      @click="touchMenuSave"
                    >保存</a-button>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
        <template v-else>
          <!-- 其他列 -->
          <a-tooltip v-if="column.msg">
            <template #title>{{ column.msg }}</template>
            {{ column.title }}
          </a-tooltip>
          <span v-else>{{ column.title }}</span>
        </template>
      </template>
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 客户端 -->
        <template v-if="column.key === 'platform_id'">
          {{ record.project_platform_name || "-" }}
        </template>
        <!-- 推广渠道 -->
        <template v-else-if="column.key === 'channel'">
          {{
            (ChannelTypes.find((item) => item.value === record.channel) || {})
              .text
          }}
        </template>
        <!-- 金额 -->
        <template v-else-if="column.key === 'pay_amount' || column.key === 'iaa_ad_income' || column.key === 'iaa_ad_income_rate' || column.key === 'total_pay_amount'">
          {{
            record[column.key] && record[column.key] != 0
              ? $pub.KEEP_NUMBER_DECIMAL(
                  (record[column.key] || 0) / 100,
                  2,
                  false,
                  true
                )
              : "-"
          }}
        </template>
        <!-- 关键事件 -->
        <template v-else-if="column.key === 'content'">
          <!-- <span style="white-space: nowrap;"> -->
            {{ record.content || "-"}}
            <a-tooltip v-if="record.content !== '不回传'">
              <template #title>{{ record.hover_content }}</template>
              <info-circle-outlined />
            </a-tooltip>
          <!-- </span> -->
        </template>
        <!-- 百分比 -->
        <template v-else-if="column.key === 'event_count_rate'">
          {{
            record[column.key] && record[column.key] != 0
              ? `${record[column.key]}%`
              : "-"
          }}
        </template>
        <!-- 操作 -->
        <template v-else-if="column.key === 'operation'">
          <a class="operation-item" @click="touchEdit(record)">查看</a>
          <a-dropdown>
            <a @click.prevent>
              更多 <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <!-- 复制链接 -->
                <!-- <a-menu-item v-if="record.channel === 4">
                  <a class="operation-item" @click="touchCopy(record)">复制链接</a>
                </a-menu-item> -->
                <!-- 优酷 + 微信 -->
                <a-menu-item v-if="(record.channel === 13 && record.client === 1)">
                  <a class="operation-item" @click="touchCopy2(record)">复制页面</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 13 && record.client === 1)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- 巨量 + 抖音 -->
                <a-menu-item v-if="(record.channel === 1 && record.client === 2)">
                  <a class="operation-item" @click="touchPage(record)">复制页面</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 1 && record.client === 2)">
                  <a class="operation-item" @click="touchParams(record)">复制参数</a>
                </a-menu-item>
                <!-- 巨量 + H5 -->
                <a-menu-item v-if="(record.channel === 1 && record.client === 5)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- 广点通 + 微信 -->
                <a-menu-item v-if="record.channel === 3 && record.platform_id === 3">
                  <a class="operation-item" @click="touchPage(record)">复制页面</a>
                </a-menu-item>
                <a-menu-item v-if="record.channel === 3 && record.platform_id === 3">
                  <a class="operation-item" @click="touchParams(record)">复制参数</a>
                </a-menu-item>
                <a-menu-item v-if="record.channel === 3 && record.platform_id === 3">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- 其他 + 微信 -->
                <a-menu-item v-if="record.channel === 4 && record.platform_id === 3">
                  <a class="operation-item" @click="touchCopy(record)">复制链接</a>
                </a-menu-item>
                <!-- 其他 + 抖音 -->
                <a-menu-item v-if="record.channel === 4 && record.platform_id === 1">
                  <a class="operation-item" @click="touchCopy(record)">复制链接</a>
                </a-menu-item>
                <!-- Vivo + 快应用 -->
                <a-menu-item v-if="(record.channel === 7 && record.client === 4)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- Wifi + 微信 -->
                <a-menu-item v-if="(record.channel === 16 && record.client === 1)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- UC + 微信 -->
                <a-menu-item v-if="(record.channel === 17 && record.client === 1)">
                  <a class="operation-item" @click="touchMonitor(record)">复制接口地址</a>
                </a-menu-item>
                <!-- 磁力 + 快手 -->
                <a-menu-item v-if="(record.channel === 8 && record.client === 3)">
                  <a class="operation-item" @click="touchPage(record)">复制页面</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 8 && record.client === 3)">
                  <a class="operation-item" @click="touchParams(record)">复制参数</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 8 && record.client === 3)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- oppo + 快应用 -->
                <a-menu-item v-if="(record.channel === 5 && record.client === 4)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- oppo + 快应用 -->
                <a-menu-item v-if="(record.channel === 5 && record.client === 4)">
                  <a class="operation-item" @click="touchFail(record)">复制失败地址</a>
                </a-menu-item>
                <!-- 每日数据 -->
                <a-menu-item>
                  <a class="operation-item" @click="touchDetail(record)">每日数据</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
        <!-- 其他字段 -->
        <template v-else>
          {{ record[column.key] || "-" }}
        </template>
      </template>
    </a-table>
    <div class="list-hint" v-if="dataSource.length">共计广告解锁 {{ sum_ad_unlock_count }} 次，预计广告收入 {{ sum_iaa_ad_income }} 元，充值金额共计 {{ sum_total_pay_amount }} 元。</div>
  </div>
  <!-- 数据详情 -->
  <DataDrawer ref="RefDataDrawer"></DataDrawer>
  <!-- 新建 -->
  <NewDrawer ref="RefNewDrawer" @success="handleSearch"></NewDrawer>
  <!-- 抵扣券 -->
  <CouponDrawer ref="RefCouponDrawer" @success="handleSearch"></CouponDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { ChannelTypes, RechargeTemplateTypes, CallbackTypes } from '@/utils/constantList'
import dayjs from 'dayjs'
import Pub from '@/utils/public'
import {
  resellerUser,
  resellerDrama,
  resellerPlatformList
} from '@/api/copyright'
import {
  iaaPopularizeList,
  iaaPopularizeRefreshTime as popularizeRefreshTime,
  iaaPopularizeRefreshData as popularizeRefreshData,
  iaaPopularizeMenuDetail as popularizeMenuDetail,
  iaaPopularizeMenuSave as popularizeMenuSave
} from '@/api/reseller'
import DataDrawer from './components-iaalink/DataDrawer.vue'
import NewDrawer from './components-iaalink/NewDrawer.vue'
import CouponDrawer from './components-iaplink/CouponDrawer.vue'
import * as Ex from "@netjoy/excelex"

// 数据详情
let RefDataDrawer = ref(null)
// 新增
let RefNewDrawer = ref(null)
// 抵扣券
let RefCouponDrawer = ref(null)
// 加载
let isLoading = ref(false)
// 自定义列菜单
let visible = ref(false)
// 更新数据
let isRefreshData = ref(false)
// 是否显示充值回传详情
let isVisibleCallbackRule = ref(false)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 日期范围
let dateRange = ref([dayjs(), dayjs()])
// 搜索
let search = ref(undefined)
// 刷新时间
let lastTime = ref(undefined)
// 推广渠道
let channelType = ref(undefined)
// 短剧
let dramas = ref([])
let dramaID = ref(undefined)
// 分销商
let resellerID = ref(undefined)
let resellers = ref([])
// 客户端
let platforms = ref([])
let platform = ref(undefined)
// 数据源
let dataSource = ref([])
// 排序字段
let order = ref('iaa_ad_income')
// 排序顺序
let orderType = ref('desc')
// 累计广告解锁
let sum_ad_unlock_count = ref(0);
// 预计广告收入
let sum_iaa_ad_income = ref(0);
// 充值金额
let sum_total_pay_amount = ref(0);
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 滚动范围
let scrollX = ref(0)
// 列数据源
let columns = ref([])
// 默认列数据源
let defColumns = ref([
  {
    title: '推广ID',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 100,
    sorter: true
  },
  {
    title: '推广链接名称',
    dataIndex: 'name',
    fixed: 'left',
    width: 200,
    key: 'name',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 200,
    fixed: 'right',
  }
])
// 自定义列数据源
let cusColumns = ref([
  {
    title: '分销商',
    dataIndex: 'reseller_name',
    key: 'reseller_name',
    width: 100,
  },
  {
    title: "短剧",
    dataIndex: "drama_name",
    key: "drama_name",
    width: 200,
  },
  {
    title: "客户端",
    dataIndex: "platform_id",
    key: "platform_id",
    width: 200,
  },
  {
    title: "付费起始",
    dataIndex: "start_pay_eq",
    key: "start_pay_eq",
    width: 100,
  },
  {
    title: "每次解锁集数",
    dataIndex: "unlock_eq_num",
    key: "unlock_eq_num",
    width: 100,
  },
  {
    title: "关键事件",
    dataIndex: "content",
    key: "content",
    width: 200,
  },
  {
    title: "推广渠道",
    dataIndex: "channel",
    key: "channel",
    width: 100,
  },
  {
    title: "访问用户",
    dataIndex: "visit_user_count",
    key: "visit_user_count",
    showSorterTooltip: false,
    width: 150,
    sorter: true,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "激活数",
    dataIndex: "activate_count",
    key: "activate_count",
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "关键事件回传数",
    dataIndex: "callback_event_count",
    key: "callback_event_count",
    showSorterTooltip: false,
    width: 200,
    sorter: true,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "事件达到率",
    dataIndex: "event_count_rate",
    key: "event_count_rate",
    showSorterTooltip: false,
    width: 200,
    sorter: true,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "预计广告收入",
    dataIndex: "iaa_ad_income",
    key: "iaa_ad_income",
    showSorterTooltip: false,
    width: 200,
    sorter: true,
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "descend"
  },
  {
    title: "人均广告收入",
    dataIndex: "iaa_ad_income_rate",
    key: "iaa_ad_income_rate",
    showSorterTooltip: false,
    width: 200,
    sorter: true,
    sortDirections: ["descend", "ascend"]
  },
  {
    title: "解锁次数",
    dataIndex: "total_unclock_count",
    key: "total_unclock_count",
    width: 100,
  },
  {
    title: "充值金额",
    dataIndex: "total_pay_amount",
    key: "total_pay_amount",
    showSorterTooltip: false,
    width: 200,
    sorter: true,
    sortDirections: ["descend", "ascend"]
  }
])

// 钩子函数
onBeforeMount (() => {
  // query带入uid字段
  const route = useRoute()
  if (route.query.id) {
    search.value = route.query.id
  }
  // 获取选择项
  getSelectList()
  // 获取刷新时间
  getRefreshTime()
  // 获取自定义列
  getMenuDetail()
})

// 获取选择项
function getSelectList () {
  resellerDrama().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      dramas.value = data
    }
  })
  resellerUser().then(res => {
    const { code, data } = res
    if (code === 0) {
      resellers.value = data
    }
  })
  resellerPlatformList({ type: 1 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      platforms.value = data
    }
  })
}

// 数据详情
function touchDetail (record) {
  RefDataDrawer.value.showDrawer(record)
}

// 新建
function touchEdit (record) {
  RefNewDrawer.value.showDrawer(record || {})
}

// 复制创建
function touchEditCopy (record) {
  RefNewDrawer.value.showDrawer(record || {}, true)
}

// 复制链接
function touchCopy (record) {
  // 落地页
  var url = Pub.LANDING_URL(record)
  Pub.COPY(url)
}

// 复制链接
function touchCopy2 (record) {
  // 落地页
  var url = Pub.LANDING_URL(record, false)
  Pub.COPY(url)
}

// 复制页面
function touchPage (record) {
  // 拷贝
  const page = Pub.LANDING_URL(record, false).split('?')[0]
  Pub.COPY(page)
}

// 复制参数
function touchParams (record) {
  // 拷贝
  const params = Pub.LANDING_URL(record, false).split('?')[1]
  Pub.COPY(params)
}

// 复制监测链接
function touchMonitor (record) {
  // 拷贝
  Pub.COPY(record.checkLinkUrl)
}

// // 抵扣券抽屉
// function touchCoupon (record) {
//   RefCouponDrawer.value.showDrawer(record)
// }

// 滚动X
function getScrollX () {
  var x = 0
  columns.value.forEach(item => {
    x += item.width
  })
}

// 复制失败链接
function touchFail (record) {
  // 拷贝
  Pub.COPY(Pub.FAIL_URL(record))
}

// 查询
function handleSearch () {
  // 设置分页
  pagination.current = 1
  // 获取列表
  getPopularizeList()
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  if (sorter.columnKey) {
    order.value = sorter.columnKey
    orderType.value = sorter.order === 'descend' ? 'desc' : 'asc'
  }
  getPopularizeList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = [dayjs(), dayjs()]
  search.value = undefined
  resellerID.value = undefined
  channelType.value = undefined
  dramaID.value = undefined
  platform.value = undefined
  // 分页重置
  pagination.current = 1
  getPopularizeList()
}

// 获取自定列列表
function getMenuDetail () {
  popularizeMenuDetail().then(res => {
    const { code, data } = res
    if (code === 0) {
      cusColumns.value.forEach(item => {
        item.status = Boolean(data[item.key])
      })
      // 刷新列
      reloadColumns()
      // 获取列表
      getPopularizeList()
    }
  })
}

// 刷新列数据
function reloadColumns () {
  const newColumns = cusColumns.value.filter(item => {
    return item.status
  })
  columns.value = [defColumns.value[0], defColumns.value[1], ...newColumns, defColumns.value[2]]
  getScrollX()
}

// 保存自定列列表
function touchMenuSave () {
  visible.value = false
  reloadColumns()
  const params = {}
  cusColumns.value.filter(item => {
    params[item.key] = item.status ? 1 : 0
  })
  popularizeMenuSave(params).then(res => {
    const { code } = res
    if (code === 0) {
      message.success('保存成功')
    } else {
      message.error(res.message || '保存失败')
    }
  }).catch(() => {
    message.error('保存失败')
  })
}

// 获取刷新时间
function getRefreshTime () {
  popularizeRefreshTime().then(res => {
    const { code, data } = res
    if (code === 0) {
      lastTime.value = data.last_time
    }
  })
}

// 刷新接口
function refreshData () {
  if (isRefreshData.value) { return }
  isRefreshData.value = true
  popularizeRefreshData().then(res => {
    const { code, data } = res
    if (code === 0) {
      lastTime.value = data.last_time
      message.success('更新成功')
      handleSearch()
    }
    isRefreshData.value = false
  }).catch(() => {
    isRefreshData.value = false
  })
}

// 获取列表
function getPopularizeList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    kw: search.value,
    channel: channelType.value,
    drama_id: dramaID.value,
    project_platform_id: platform.value,
    reseller_id: resellerID.value,
    order: order.value,
    order_type: orderType.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  iaaPopularizeList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach((item) => {
        item.type = "iaa"
      })
      sum_ad_unlock_count.value = data.sum_ad_unlock_count
      sum_iaa_ad_income.value = data.sum_iaa_ad_income
      sum_total_pay_amount.value = data.sum_total_pay_amount
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 列表自定义Row
function customRow (record) {
  return ({
    onMouseenter () {
      record.canEdit = true
    },
    onMouseleave () {
      record.canEdit = false
    }
  })
}

// 导出
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    kw: search.value,
    channel: channelType.value,
    drama_id: dramaID.value,
    project_platform_id: platform.value,
    reseller_id: resellerID.value,
    order: order.value,
    order_type: orderType.value,
    page: 1,
    page_size: 10000
  }
  iaaPopularizeList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      if (list.length) {
        const tablecolumns = []
        columns.value.forEach(item => {
          if (item.key !== 'operation') {
            tablecolumns.push({
              name: item.title,
              field: item.key
            })
          }
        })
        const sheets = [
          {
            data: list,
            columns: tablecolumns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            if (field === "platform_id") {
              item.data = json.project_platform_name;
            } else if (field === "channel") {
              item.data =(ChannelTypes.find((item) => item.value === json[field]) || {}).text || "-";
            } else if (field === "pay_amount" || field === "iaa_ad_income" || field === "iaa_ad_income_rate" || field === "total_pay_amount") {
              item.data = Pub.KEEP_NUMBER_DECIMAL(json[field] / 100, 2, false, true);
              return item;
            } else if (field === "event_count_rate") {
              item.data = `${json[field] || 0}%`;
              return item;
            } else {
              item.data = json[field] || "-";
            }
          }
          return item;
        },  `分销管理-IAA推广链接汇总`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch((err) => {
    console.log(err)
    message.error('导出失败')
  })
}

</script>

<style scoped>
.table-view {
  margin-top: 30px;
}
.alert-view {
  margin-top: 20px;
}

.break-span{
  word-break: break-all;
  word-wrap: break-word;
}
.list-view {
  position: relative;
  .list-hint {
    position: absolute;
    bottom: 14px;
  }
}
</style>